.nav-strip p{
	font-size: 25px;
	color: #fff;
	text-align: center;
	width: 100%;
	padding-top: 2vh;  
	padding-bottom: 2vh;  
}
header{
	background-color: lightblue;
/*	background: url("./assets/images/header-runner.jpg"); */
/*	height:80vh ;  */
}
ptn{
  background: url("./assets/images/even-bg.jpg") center; 
  /*height: 50px; */
}
.login-container{
  text-align: center;
  width: 100%;
  
}
.login-container .form-control:focus{
	box-shadow: none;
	border-color: #ced4da; 
}
.login-container .form-control{
  padding: 30px; 
}

.login-container .number{
margin-bottom: 0;

}
.login-container .input-group-text{
  
  background: none;
  border-radius: 8px 0 0 0 ;
}
.login-container .number .input-group-text{
  border-radius: 8px 0 0 0 ;

}
.login-container .password .input-group-text{
  border-radius: 8px 0 0 0 ;
}
.login-container .mobile{
  border-radius: 0px 8px 0  0;
	border-bottom: 0; 
  padding-top: 30px;
  
  padding-bottom: 30px;
 
  
  
  
 background-color: #F0F8FF; 
  
}
.login-container .password{
  background-color: #F0F8FF;
  border-radius: 0px 0 8px  0;

	/*border-bottom: 0; */
 
  padding-top: 30px;
  
  padding-bottom: 30px;
  
  
/*   */
}
.btn-signin{
	background: #ff5043;
	border:0;
	color: #fff;
	border-radius: 10px;
	padding: 20px 50px;
	font-weight: 600;
	margin-top: 15px;
	margin-right: 16px;
}
.btn-primary-spacing 
{
margin-right: 25px;
margin-bottom: 5px !important;
}

hr.new5 {
	border: 1px solid green;
	border-radius: 1px;
}

@media only screen and (max-width: 767px) {
	.btn-signin{
		margin-bottom: 50px;
	}
	.login-container p{
		font-size: 14px;
		padding-top: 15px;
	}
}
.login-center-wrap{
	height: 150px;
	position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
}
form{
  margin-top: 80px;
 border-bottom: 0;
}
.alert-danger{
	font-size: 13px;
}
.forgot-password{
	font-size: 15px;
    color: #1251a8 !important;
    cursor: pointer;
}
.reset-pass{
	text-align: center;

}
.reset-pass p{
	margin-bottom: 30px;
}
.reset{padding: 15px 0; font-size: 13px;background: #ff5043; color: #fff;
	margin-top: 15px; display: inline-block;width: 100%;
 text-align: center;}
 .reset:hover{
 	text-decoration: none;
 }
 .alignleft {
    float: left;
    text-align:left;
    width:33.33333%;
}
.aligncenter {
    float: left;
    text-align:center;
    width:33.33333%;
}
.alignright {
    float: left;
    text-align:right;
    width:33.33333%;
}
.MuiTableCell-root {
	font-size: 0.5 rem
}
.center {
	margin: auto;
	width: 50%;
	max-width: 400px;
	min-width: 150px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 20px;
  }
  div.loader {
	font-size: 200px;
  }
  
  .loaded {
	font-size: 1.2em;
	font-weight: bold;
	margin-bottom: 10px;
  }
  